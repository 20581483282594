import cx from "classnames";
import { addDoc, collection } from "firebase/firestore";
import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React, { ReactNode, useState } from "react";
import { useHomepage } from ".";
import Button from "../components/Button";
import { CommonTranslation, useCommonTranslations } from "../components/CommonTranslations";
import { Input } from "../components/Input";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useFilteredByLocale } from "../hooks";
import AllOutLogo from "../images/allout-logo.svg";
import { db } from "./inquiries-and-faqs";

export default function FightForLove({ location }: { location: Location }) {
  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title="#fightforlove" />
      <Body />
    </Layout>
  );
}

const Body = () => {
  return (
    <div className="w-full">
      <FightForLoveSection />
      <AllOut />
      <DayAgainstHomophobia />
      <BookForFestival />
    </div>
  );
};

const FightForLoveSection = () => {
  const { title, subtitle, paragraphs, quote } = useFightForLove();

  return (
    <div className="flex items-center justify-center w-full min-h-screen pt-32 pb-20 text-white bg-primaryDark">
      <div className="flex flex-col w-3/4 mx-auto">
        <div className="flex mb-12">
          <h1 className="inline-block text-4xl font-bold border-b-4 border-opacity-50 md:text-5xl border-primary">
            {title}
          </h1>
        </div>
        <h2 className="mb-6 text-lg font-semibold md:text-xl">{subtitle}</h2>
        {(paragraphs || []).map(({ text }) => (
          <h2 className="mb-6 md:text-lg">{text}</h2>
        ))}
        <div className="flex mt-4 gap-x-4">
          <div className="w-3 bg-primary"></div>
          <div className="flex flex-col space-y-2">
            <h3 className="text-lg italic md:text-xl">{quote}</h3>
            <h4 className="font-bold md:text-lg">
              Peeter Rebane, <CommonTranslation value="director" />
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const variants = {
  rest: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
};

const AllOut = () => {
  const { allOutText, allOutButtonText } = useFightForLove();

  return (
    <div className="flex items-center justify-center w-full min-h-screen py-20 text-white bg-black">
      <div className="flex flex-col w-3/4 mx-auto text-center md:w-1/2 lg:text-left">
        <a href="https://allout.org/en" target="_blank" className="w-64 mx-auto mb-10">
          <motion.img
            whileHover="hover"
            className="px-4 py-4"
            variants={variants}
            src={AllOutLogo}
            alt="allout organization logo"
          />
        </a>
        {(allOutText || []).map(({ text }) => (
          <h3 className="mb-6 md:text-xl">{text}</h3>
        ))}
        <div className="flex justify-center">
          <a href="https://allout.org/en" target="_blank" className="px-4 py-3 font-semibold rounded-md bg-allout">
            {allOutButtonText}
          </a>
        </div>
      </div>
    </div>
  );
};

const DayAgainstHomophobia = () => {
  const { idahtbSections, idahtbSubtitle, idahtbTitle } = useFightForLove();
  return (
    <div className="flex items-center justify-center w-full min-h-screen py-20 bg-primaryDark">
      <div className="flex flex-col w-3/4 mx-auto text-white">
        <h1 className="mb-2 text-xl font-semibold md:text-2xl">{idahtbTitle}</h1>
        <div className="flex">
          <h2 className="inline-block mb-8 text-xl font-semibold border-b-4 border-opacity-50 md:text-2xl border-primary">
            {idahtbSubtitle}
          </h2>
        </div>
        {(idahtbSections || []).map(({ title, body }) => (
          <Section title={title ?? ""} className="mb-6">
            <p>{body}</p>
          </Section>
        ))}
      </div>
    </div>
  );
};

interface SectionProps {
  title: string;
  children: ReactNode;
  className?: string;
}

const Section = ({ title, children, className }: SectionProps) => {
  return (
    <div className={cx("", className)}>
      <h3 className="mb-2 text-lg font-semibold">{title}</h3>
      {children}
    </div>
  );
};

const BookForFestival = () => {
  const { bookFirebirdTitle, bookFirebirdSubtitle, bookFirebirdText } = useHomepage();

  return (
    <div className="flex items-center justify-center w-full min-h-screen py-20 bg-black">
      <div className="flex flex-col w-3/4 mx-auto text-center text-white md:w-1/2 lg:text-left">
        <h1 className="mb-4 text-2xl font-bold">{bookFirebirdTitle}</h1>
        <p className="mb-4">{bookFirebirdText}</p>
        <h2 className="text-lg font-bold">{bookFirebirdSubtitle}</h2>
        <FestivalForm />
      </div>
    </div>
  );
};

interface FestivalFormProps {
  name: string;
  email: string;
  message: string;
}

export const FestivalForm = () => {
  const [formData, setFormData] = useState<FestivalFormProps>({ name: "", email: "", message: "" });
  const [formError, setFormError] = useState<null | string>();
  const [formSuccess, setFormSuccess] = useState<null | string>();
  const { firstName, email, message, isRequired } = useCommonTranslations();

  const updateField = (field: keyof FestivalFormProps, value: string) => {
    setFormData((form) => ({ ...form, [field]: value }));
  };

  const onSubmit = async () => {
    if (!formData.name) return setFormError(`${firstName} ${isRequired}!`);
    if (!formData.email) return setFormError(`${email} ${isRequired}!`);
    if (!formData.message) return setFormError(`${message} ${isRequired}!`);
    try {
      await addDoc(collection(db, "contact-us"), {
        to: "cwracster@mac.com",
        message: {
          subject: "[firebirdmovie.com] New booking request from homepage!",
          html: `
        <p><b>Name:</b> ${formData.name}</p>
        <p><b>Email:</b> ${formData.email}</p>
        <p><b>Message:</b> ${formData.message}</p>
        `,
        },
      });
      setFormSuccess("Message sent successfully!");
      setFormData({ message: "", email: "", name: "" });
      setFormError(null);
    } catch (e) {
      setFormError("Something went wrong. Please try again!");
    }

    setTimeout(() => setFormSuccess(null), 10000);
  };

  return (
    <div
      className={cx("flex flex-col", {
        "mt-8": !formError,
        "mt-2": formError,
      })}
    >
      {formError && <p className="mb-8 font-bold text-center text-primary">{formError}</p>}
      {formSuccess && <p className="mb-8 font-bold text-center text-green-400">{formSuccess}</p>}
      <div className="flex flex-col mb-8 space-y-8 md:space-y-0 md:flex-row gap-x-4">
        <Input
          label={`${firstName}*`}
          value={formData.name}
          onChange={({ target: { value } }) => updateField("name", value)}
        />
        <Input
          label={`${email}*`}
          value={formData.email}
          onChange={({ target: { value } }) => updateField("email", value)}
        />
      </div>
      <Input
        containerClass="mb-8"
        label={`${message}*`}
        value={formData.message}
        onChange={({ target: { value } }) => updateField("message", value)}
      />
      <div className="flex justify-center">
        <Button className="h-10" onClick={onSubmit}>
          <CommonTranslation value="send" />
        </Button>
      </div>
    </div>
  );
};

const useFightForLove = (): FightForLove => {
  const {
    allStrapiFightForLove: { edges },
  } = useStaticQuery<{ allStrapiFightForLove: EdgesQuery<{ node: FightForLove }> }>(query);

  return useFilteredByLocale(edges.map(({ node }) => node))[0];
};

const query = graphql`
  query FightForLove {
    allStrapiFightForLove {
      edges {
        node {
          title
          subtitle
          paragraphs {
            text
          }
          quote
          allOutText {
            text
          }
          allOutButtonText
          idahtbTitle
          idahtbSubtitle
          idahtbSections {
            title
            body
          }
          locale
        }
      }
    }
  }
`;
