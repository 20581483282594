import { Link } from "gatsby";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import LanguageMenu, { LocaleAwareLink } from "./LanguageMenu";
import { LogoWithAuthor } from "./Logo";
import Socials from "./Socials";
import { motion, SVGMotionProps } from "framer-motion";
import { CommonTranslations, useCommonTranslations } from "./CommonTranslations";
import cx from "classnames";
import { useRegion } from "./Region";

export const MenuContext = createContext({ open: false, setOpen: () => {} } as {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
});

export const useMenu = () => {
  return useContext(MenuContext);
};

export const MenuContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [open, setOpen] = useState(false);

  const context = useMemo(() => {
    return {
      open,
      setOpen,
    };
  }, [open, setOpen]);

  return <MenuContext.Provider value={context}>{children}</MenuContext.Provider>;
};

const variants = {
  active: {
    width: "100vw",
    backgroundColor: "rgb(0, 0, 0)",
    opacity: 100,
  },
  inactive: {
    width: "0vw",
    backgroundColor: "rgb(0, 0, 0)",
    opacity: 0,
  },
};

export default function Menu() {
  const { open, setOpen } = useMenu();
  const commonTranslations = useCommonTranslations();
  const { showTicketsPopup, ticketsUrl } = useRegion();

  const MENU_ITEMS: { title: keyof CommonTranslations; to: string; className?: string }[] = [
    { title: "home", to: "/" },
    {
      title: showTicketsPopup && ticketsUrl ? "getTickets" : "watchNow",
      to: showTicketsPopup && ticketsUrl ? ticketsUrl : "/watch-now",
    },
    { title: "theBook", to: "/the-book" },
    { title: "shop", to: "/shop" },
    { title: "cast", to: "/cast" },
    { title: "sergeysStory", to: "/sergeys-story" },
    { title: "directorsVision", to: "/directors-vision" },
    { title: "behindTheScenes", to: "/behind-the-scenes" },
    // { title: "festivalScreenings", to: "/festival-screenings" },
    { title: "fightForLove", to: "/fight-for-love", className: "bg-primary" },
    { title: "pressAreaAndContact", to: "/inquiries-and-faqs" },
  ];

  const mappedMenuItems = MENU_ITEMS.map(({ title, to }) => ({ title: commonTranslations[title], to }));

  return (
    <motion.div
      className="fixed top-0 left-0 z-40 h-screen overflow-hidden"
      animate={open ? "active" : "inactive"}
      initial={{ opacity: 0 }}
      variants={variants}
      transition={{ type: "spring", mass: 0.1 }}
    >
      <motion.div
        className="relative flex flex-col h-full"
        animate={open ? "active" : "inactive"}
        variants={{
          active: { opacity: 100, transition: { duration: 3 } },
          inactive: { opacity: 0, transition: { duration: 0 } },
        }}
      >
        <div className="flex justify-center w-full px-8 pt-16 pb-20 sm:px-0 sm:mx-auto sm:w-2/3 md:w-1/3 lg:w-1/3">
          <LogoWithAuthor />
        </div>
        <div className="flex flex-col flex-grow">
          {mappedMenuItems.map((props, i) => (
            <MenuLink key={i} onClick={() => setOpen(false)} {...props} />
          ))}
        </div>
        <div className="flex justify-end mt-4 mb-4 mr-8 space-x-6">
          <Socials />
        </div>
      </motion.div>
    </motion.div>
  );
}

interface MenuLinkProps {
  to: string;
  title: string;
  onClick(): void;
  className?: string;
}

export const MenuLink = ({ to, title, onClick, className }: MenuLinkProps) => (
  <LocaleAwareLink
    to={to}
    onClick={onClick}
    activeClassName="bg-white text-black"
    className={cx(
      "flex items-center justify-center flex-grow max-h-16 w-full text-white hover:bg-white hover:text-black text-lg md:text-2xl font-bold uppercase text-center",
      className
    )}
  >
    {title}
  </LocaleAwareLink>
);

const Path = (props: SVGMotionProps<SVGPathElement>) => (
  <motion.path fill="transparent" strokeWidth="3" stroke="white" strokeLinecap="round" {...props} />
);

export const MenuButton = () => {
  const { setOpen, open } = useMenu();
  const animate = open ? "open" : "closed";

  return (
    <button className="flex items-center h-full" onClick={() => setOpen(!open)}>
      <svg className="w-5 h-5" viewBox="0 0 23 23">
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
          animate={animate}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
          animate={animate}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
          animate={animate}
        />
      </svg>
    </button>
  );
};
